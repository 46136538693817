<template>
  <div>
    <form @submit.prevent="login(user)">
      <label for="user">
        Correo
      </label>
      <div>
        <b-form-input
          id="user"
          v-model="user.username"
          name="user"
          type="text"
        />
      </div>
      <label for="pass">
        Contraseña
      </label>
      <div>
        <b-form-input
          id="pass"
          v-model="user.password"
          name="pass"
          type="password"
        />
      </div>
      <div class="row match-heigh mt-3">
        <b-button
          type="submit"
          class="col"
          variant="success  w-full"
        >
          Ingresar
        </b-button>
        <br>
        <a
          variant="primary mx-2"
          class="col"
          href="/portal-register"
        >
          Registrar
        </a>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: { username: null, password: null },
    }
  },
  methods: {
    login(user) {
      this.$store
        .dispatch('client/LOGIN_CLIENT', user)
        .then(() => {
          if (this.$route.name === 'portal-register') {
            window.location.assign('/portal-web')
          } else {
            window.location.reload()
          }
        })
        .catch(() => {
          this.$swal({
            title: 'Error!',
            text: 'Correo o contraseña invalida.',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },
  },
}
</script>

<style></style>

<template>
  <div class="d-none d-md-block">
    <nav class="social-network">
      <ul>
        <li v-if="facebook">
          <a
            target="_blank"
            :href="facebook"
          ><font-awesome-icon
            size="lg"
            :icon="['fab', 'facebook']"
          /><span>Facebook</span>
          </a>
        </li>

        <li v-if="twitter">
          <a
            target="_blank"
            :href="twitter"
          ><font-awesome-icon
            size="lg"
            :icon="['fab', 'twitter']"
          /><span>Twitter</span></a>
        </li>
        <li v-if="instagram">
          <a
            target="_blank"
            :href="instagram"
          ><!-- <feather-icon
            size="24"
            icon="InstagramIcon"
          /> -->
            <font-awesome-icon
              size="lg"
              :icon="['fab', 'instagram']"
            /><span>Instagram</span></a>
        </li>
        <li v-if="linkedin">
          <a
            target="_blank"
            :href="linkedin"
          ><!-- <feather-icon
            size="24"
            icon="LinkedinIcon"
          /> -->
            <font-awesome-icon
              size="lg"
              :icon="['fab', 'linkedin-in']"
            /><span>Linkedin</span></a>
        </li>
        <li v-if="github">
          <a
            target="_blank"
            :href="github"
          >
            <font-awesome-icon
              size="lg"
              :icon="['fab', 'github']"
            /><span>Github</span></a>
        </li>
        <li v-if="youtube">
          <a
            target="_blank"
            :href="youtube"
          >
            <font-awesome-icon
              size="lg"
              :icon="['fab', 'youtube']"
            /><span>Youtube</span></a>
        </li>
        <li v-if="flickr">
          <a
            target="_blank"
            :href="flickr"
          >
            <font-awesome-icon
              size="lg"
              :icon="['fab', 'flickr']"
            /><span>Flickr</span></a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  props: ['redes'],
  data() {
    return {
      youtube: null,
      facebook: null,
      instagram: null,
      flickr: null,
      github: null,
      linkedin: null,
      twitter: null,
    }
  },
  mounted() {
    this.youtube = this.redes.youtube
    this.facebook = this.redes.facebook
    this.instagram = this.redes.instagram
    this.flickr = this.redes.flickr
    this.github = this.redes.github
    this.linkedin = this.redes.linkedin
  },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Montserrat:600&display=swap');

* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
}

body {
  font-family: 'Montserrat', sans-serif;
  background: url(https://i.imgur.com/3Ad4Atf.jpg);
  background-position: center;
  background-size: cover;
  height: 100vh;
}

nav {
  z-index: 99999999;
  background: white;
  position: fixed;
  width: 60px;
  margin-top: 270px;
  transition: all 0.3s linear;
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.4);
}

nav ul {
  margin-top: 0;
  margin-bottom: 0rem;
}

nav li {
  height: 45px;
  position: relative;
}

nav li a {
  color: #fff !important;
  display: block;
  height: 100%;
  width: 100%;
  line-height: 45px;
  padding-left: 25%;
  transition: all 0.3s linear;
  text-decoration: none !important;
}

nav li a {
  color: rgb(160, 160, 160) !important;
}

nav li a i {
  position: absolute;
  top: 14px;
  left: 24px;
  font-size: 15px;
}

ul li a span {
  display: none;
  font-weight: bold;
  margin-top: -45px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

a:hover {
  z-index: 1;
  width: 200px;
}

ul li:hover a span {
  padding-left: 30%;
  display: block;
  font-size: 15px;
}
</style>

<template>
  <div
    :style="'--color: ' + color + '; --colorTextLink:' + colorTextLink + ';'"
  >
    <SocialNetwork :redes="redes" />

    <div class="navbar-arq">
      <b-navbar
        toggleable="lg"
        type="ligth"
        variant="primary"
      >
        <router-link to="/portal-web">
          <img
            src="/epa.png"
            height="50"
            alt=""
          >
        </router-link>
        <b-navbar-toggle target="nav-collapse" />

        <b-collapse
          id="nav-collapse"
          is-nav
        >
          <b-navbar-nav />
        </b-collapse>
        <div
          v-if="!client.idresponsable"
          class="cursor-pointer"
          @click="openModalLogin()"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          /> Login
        </div>
        <div
          v-else
          class="w-flex"
        >
          {{ client.nombrerespserv + ' ' + client.primerapellido }}
          <div
            class="mx-50 cursor-pointer "
            @click="logout()"
          >
            salir
            <feather-icon
              size="16"
              icon="LogOutIcon"
              tittle="Salir"
            />
          </div>
        </div>
      </b-navbar>
      <b-modal
        id="modal-logout"
        title="Ingresar"
      />
      <b-modal
        id="modal-login"
        title="Ingresar"
      >
        <PortalLogin />
        <template #modal-footer="{ ok, cancel, hide }">
          <!-- Emulate built in modal footer ok and cancel button actions -->
          <b-button
            hidden
            @click="ok()"
          >
            OK
          </b-button>
          <b-button
            hidden
            @click="cancel()"
          >
            Cancel
          </b-button>
          <!-- Button with custom close trigger value -->
          <b-button
            hidden
            @click="hide('forget')"
          >
            Forget it
          </b-button>
        </template>
      </b-modal>
    </div>
    <router-view />
    <div hidden>
      <input
        v-model="color"
        type="color"
      >
      <input
        v-model="colorTextLink"
        type="color"
      >
    </div>
  </div>
</template>

<script>
import SocialNetwork from '@core/components/standar/SocialNetwork.vue'
import { mapState } from 'vuex'
import PortalLogin from './portal/PortalLogin.vue'

export default {
  components: {
    PortalLogin,
    SocialNetwork,
  },
  data() {
    return {
      color: '#0076a3',
      colorIcon: '#0076a3',
      colorTextLink: '#fff',
      logo: 'Logo',
      url: null,
      cards: [],
      redes: {
        youtube: 'https://www.youtube.com/channel/UC7aWkW00l4isAqPh6dyyJrA',
        facebook: 'https://www.facebook.com/EPA.ARMENIA',
        instagram: 'https://www.instagram.com/epa_armenia/',
        flickr: 'https://www.flickr.com/photos/146031145@N02/',
      },
    }
  },
  computed: {
    ...mapState('client', ['client']),
  },
  methods: {
    openModalLogin() {
      this.$bvModal.show('modal-login')
    },
    logout() {
      this.$store.dispatch('client/LOGOUT_CLIENT').then(() => {
        window.location.reload()
      })
    },
  },
}
</script>

<style>
.navbar-arq .cursor-pointer {
  cursor: pointer;
}
.navbar-arq nav {
  background-color: var(--color) !important;
  color: var(--colorTextLink) !important;
}
.navbar-arq button {
  color: var(--colorTextLink) !important;
  background-color: var(--color) !important;
}
.navbar-arq a {
  color: var(--colorTextLink) !important;
  background-color: var(--color) !important;
}
.navbar-arq a {
  color: var(--colorTextLink) !important;
  background-color: var(--color) !important;
}
</style>
